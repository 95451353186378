import { Link } from 'gatsby'
import React from 'react'
import { overrideTailwindClasses } from 'tailwind-override'

type ButtonSize = 'large' | 'medium' | 'small'
type ButtonVariant = 'primary' | 'white' | 'error' | 'outlined'

interface BaseButtonProps {
  size?: ButtonSize
  variant?: ButtonVariant
  disabled?: boolean
  className?: string
  useLink?: boolean
}

interface UrlButtonProps extends BaseButtonProps {
  url: string
}

interface FunctionButtonProps extends BaseButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement> | null
  type?: 'button' | 'submit' | 'reset'
}

const commonClasses = 'rounded-full font-semibold whitespace-nowrap'

const variantClasses: { [K in ButtonVariant]: string } = {
  primary:
    'bg-blue-500 hover:bg-white text-white hover:text-slate-800 border-2 border-blue-500 hover:border-white',
  white:
    'bg-white hover:bg-slate-600 text-slate-800 hover:text-white border-2 border-white hover:border-bg-slate-600',
  error:
    'bg-red-400 hover:bg-red-300 border-2 border-red-400 hover:border-red-300',
  outlined: 'border-2 border-white hover:bg-white hover:text-slate-800',
}

const sizeClasses: { [K in ButtonSize]: string } = {
  small: 'px-3 py-1 text-xs',
  medium: 'px-5 py-2 text-sm',
  large: 'px-7 py-2 text-md',
}

function getEffectiveClassName(
  size?: ButtonSize,
  variant?: ButtonVariant,
  className?: string
) {
  const variantClass = variantClasses[variant ?? 'primary']
  const sizeClass = sizeClasses[size ?? 'medium']
  return overrideTailwindClasses(
    `${sizeClass} ${variantClass} ${commonClasses} ${className}`
  )
}

/**
 * Common dynamical semantic button component – uses <button>, <a>,
 * or <Link>, depending on the context.
 *
 * When `onClick` prop is defined, the component is rendered using
 * <button>.
 *
 * When used with `url` property, this component will be rendered
 * using <a>, or <Link>. If `useLink` property is `true`, <Link> is
 * always used, when `false`, <a> always used, and if not set,
 * <Link> is used only when the URL looks like an external URL.
 *
 * Both `url` and `onClick` props cannot be used at the same time.
 */
const Button = (
  props: React.PropsWithChildren<UrlButtonProps | FunctionButtonProps>
) => {
  const explicitelyUsedLinkType = props.useLink === true
  const explicitelyDisabledLinkType = props.useLink === false

  if ('onClick' in props) {
    return FunctionButton(props)
  } else {
    const seemsLikeInternalLink = /^\/(?!\/)/.test(props.url)

    if (
      !explicitelyDisabledLinkType &&
      (explicitelyUsedLinkType || seemsLikeInternalLink)
    ) {
      return LinkButton(props)
    } else {
      return HrefButton(props)
    }
  }
}

const LinkButton = ({
  children,
  size,
  variant,
  url,
  className,
  disabled,
}: React.PropsWithChildren<UrlButtonProps>) => {
  // TODO: Implement support for disabled prop
  return (
    <Link to={url} className={getEffectiveClassName(size, variant, className)}>
      {children}
    </Link>
  )
}

const HrefButton = ({
  children,
  size,
  variant,
  url,
  className,
  disabled,
}: React.PropsWithChildren<UrlButtonProps>) => {
  // TODO: Implement support for disabled prop
  // Internal page link buttons (#{id}) should not open in new tab
  const isHashLink = url.startsWith('#')
  return (
    <a
      href={url}
      target={isHashLink ? '' : '_blank'}
      className={getEffectiveClassName(size, variant, className)}
    >
      {children}
    </a>
  )
}

const FunctionButton = ({
  children,
  size,
  variant,
  onClick,
  className,
  disabled,
}: React.PropsWithChildren<FunctionButtonProps>) => {
  // TODO: Implement styles for disabled prop
  return (
    <button
      onClick={onClick ?? undefined}
      className={
        getEffectiveClassName(size, variant, className) +
        (disabled ? ' opacity-20' : '')
      }
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default Button
