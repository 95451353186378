import { Reset, Close } from '@carbon/icons-react'
import React, { useState } from 'react'

async function refreshData() {
  const response = await fetch('/__refresh', { method: 'POST' })

  if (response.ok) {
    window.location.reload()
  } else {
    if (response.status == 404) {
      alert(
        'The environment variable ENABLE_GATSBY_REFRESH_ENDPOINT ' +
          'must be set to refresh the data dynamically.'
      )
    }
  }
}

/**
 * A specific debug component used for development and preview purposes,
 * with fixed bottom-left position, allows visitor to see breakpoints
 * and refresh GraphQL data.
 */
const DebugFloater = ({}) => {
  const [isVisible, setVisibility] = useState(true)

  if (!isVisible) return null

  return (
    <div className="fixed z-50 left-10 bottom-10 p-3 bg-white shadow-lg opacity-30 hover:opacity-90 transition-opacity rounded-full rounded-bl-none">
      {/* Refresh data button */}
      <button
        onClick={refreshData}
        className="block group bg-red-600 text-white rounded-full w-12 h-12"
      >
        <span className="block group-hover:hidden">
          <Reset className="mx-auto" />
        </span>
        <span className="hidden group-hover:block text-xs leading-3">
          Refresh
          <br />
          data
        </span>
      </button>

      {/* Breakpoint display */}
      <div className="flex h-5 my-2">
        {['sm:block', 'md:block', 'lg:block', 'xl:block'].map((breakpoint) => (
          <div
            key={breakpoint}
            className={`hidden ${breakpoint} h-4 w-3 text-[12px] rotate-90 uppercase`}
          >
            {breakpoint.split(':')[0]}&nbsp;
          </div>
        ))}
      </div>

      {/* Close button */}
      <button onClick={() => setVisibility(false)} className="block">
        <Close />
      </button>
    </div>
  )
}

export default DebugFloater
