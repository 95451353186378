import { Checkmark, Close } from '@carbon/icons-react'
import { Link } from 'gatsby-plugin-react-i18next'
import React from 'react'
import Button from './button'

interface CookieConsentBannerProps {
  privacyPolicyLinkUrl: string
  showAlways?: boolean
}

const PREFERENCE_KEY = 'cookiesConsentPreference'

const CookieConsentBanner = ({
  privacyPolicyLinkUrl,
  showAlways = false,
}: CookieConsentBannerProps) => {
  const userHasDecided = localStorage.getItem(PREFERENCE_KEY) != null
  const [showBanner, setShowBanner] = React.useState<boolean>(
    !userHasDecided || showAlways
  )

  const handleConsent = (preference: boolean) => {
    setShowBanner(false)
    localStorage.setItem(PREFERENCE_KEY, preference ? '1' : '0')

    if (preference === false) {
      // Calls opt-out function of the gatsby-plugin-google-gtag plugin
      // This will opt-out permanently (disables GA via cookies)
      if ('gaOptout' in window && typeof window.gaOptout === 'function') {
        window.gaOptout()
      }
    }
  }

  if (!showBanner) return null

  return (
    <div className="bg-slate-100/90 text-slate-600 rounded-lg shadow-lg p-4 max-w-[90vw]">
      <div className="flex gap-6 justify-center items-center text-sm">
        <p>
          We use 3rd-party cookies to ensure you get the best experience on our
          website.
          <Link
            to={privacyPolicyLinkUrl}
            className="underline whitespace-nowrap ml-2"
            target="_blank"
          >
            Learn more
          </Link>
        </p>
        <div className="flex gap-3 flex-wrap sm:flex-nowrap">
          <Button size="small" onClick={() => handleConsent(true)}>
            <Checkmark className="hidden md:inline mr-2" />
            Understood
          </Button>
          <Button
            size="small"
            variant="white"
            onClick={() => handleConsent(false)}
          >
            <Close className="hidden md:inline mr-2" />
            No, thanks
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CookieConsentBanner
