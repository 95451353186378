import './src/styles/global.css'
import React from 'react'
import DebugFloater from './src/components/common/debug-floater'
import CookieConsentBanner from './src/components/common/cookie-consent-banner'

// NOTE: This file cannot be included in tailwind sources
// thus no tailwind classes can be used here.

export const wrapPageElement = ({ element, props }) => {
  return (
    <>
      {/* Elements displayed globally on all pages */}
      {process.env.GATSBY_SHOW_DEBUG_FLOATER ? <DebugFloater /> : null}
      <div
        style={{
          position: 'fixed',
          bottom: '0.75rem',
          right: '0.75rem',
          zIndex: 50,
        }}
      >
        <CookieConsentBanner privacyPolicyLinkUrl="/privacy-policy" />
      </div>

      {/* Page contents */}
      {element}
    </>
  )
}

export const onRouteUpdate = ({ location }) => scrollToAnchor(location)
/**
 * from https://github.com/gatsbyjs/gatsby/issues/21120#issuecomment-581141246
 * @desc - a function to jump to the correct scroll position
 * @param {Object} location -
 * @param {Number} [mainNavHeight] - the height of any persistent nav -> document.querySelector(`nav`)
 */
function scrollToAnchor(location, mainNavHeight = 0) {
  // Check for location so build does not fail
  if (location && location.hash) {
    const hash = location.hash.replace(/^#(\d)/, '#\\3$1')

    const itemOffset = document?.querySelector(hash)?.offsetTop

    if (itemOffset === undefined) return false

    window.scrollTo({
      top: itemOffset - mainNavHeight,
      behavior: 'smooth',
    })
  }

  return true
}
